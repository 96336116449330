// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-static-about-js": () => import("./../../../src/templates/static/about.js" /* webpackChunkName: "component---src-templates-static-about-js" */),
  "component---src-templates-static-careers-js": () => import("./../../../src/templates/static/careers.js" /* webpackChunkName: "component---src-templates-static-careers-js" */),
  "component---src-templates-static-case-study-js": () => import("./../../../src/templates/static/case-study.js" /* webpackChunkName: "component---src-templates-static-case-study-js" */),
  "component---src-templates-static-contact-us-js": () => import("./../../../src/templates/static/contact-us.js" /* webpackChunkName: "component---src-templates-static-contact-us-js" */),
  "component---src-templates-static-index-js": () => import("./../../../src/templates/static/index.js" /* webpackChunkName: "component---src-templates-static-index-js" */),
  "component---src-templates-static-insights-js": () => import("./../../../src/templates/static/insights.js" /* webpackChunkName: "component---src-templates-static-insights-js" */),
  "component---src-templates-static-itc-js": () => import("./../../../src/templates/static/itc.js" /* webpackChunkName: "component---src-templates-static-itc-js" */),
  "component---src-templates-static-news-js": () => import("./../../../src/templates/static/news.js" /* webpackChunkName: "component---src-templates-static-news-js" */),
  "component---src-templates-static-newsletter-subscription-js": () => import("./../../../src/templates/static/newsletter-subscription.js" /* webpackChunkName: "component---src-templates-static-newsletter-subscription-js" */),
  "component---src-templates-static-not-found-js": () => import("./../../../src/templates/static/not-found.js" /* webpackChunkName: "component---src-templates-static-not-found-js" */),
  "component---src-templates-static-product-amplify-js": () => import("./../../../src/templates/static/product-amplify.js" /* webpackChunkName: "component---src-templates-static-product-amplify-js" */),
  "component---src-templates-static-product-enterprise-js": () => import("./../../../src/templates/static/product-enterprise.js" /* webpackChunkName: "component---src-templates-static-product-enterprise-js" */),
  "component---src-templates-static-product-overview-js": () => import("./../../../src/templates/static/product-overview.js" /* webpackChunkName: "component---src-templates-static-product-overview-js" */),
  "component---src-templates-static-product-unify-js": () => import("./../../../src/templates/static/product-unify.js" /* webpackChunkName: "component---src-templates-static-product-unify-js" */),
  "component---src-templates-static-request-demo-js": () => import("./../../../src/templates/static/request-demo.js" /* webpackChunkName: "component---src-templates-static-request-demo-js" */),
  "component---src-templates-static-use-case-independent-agencies-js": () => import("./../../../src/templates/static/use-case-independent-agencies.js" /* webpackChunkName: "component---src-templates-static-use-case-independent-agencies-js" */),
  "component---src-templates-static-use-case-insurance-carriers-js": () => import("./../../../src/templates/static/use-case-insurance-carriers.js" /* webpackChunkName: "component---src-templates-static-use-case-insurance-carriers-js" */),
  "component---src-templates-static-use-case-insurance-distributors-js": () => import("./../../../src/templates/static/use-case-insurance-distributors.js" /* webpackChunkName: "component---src-templates-static-use-case-insurance-distributors-js" */),
  "component---src-templates-static-use-case-market-innovators-js": () => import("./../../../src/templates/static/use-case-market-innovators.js" /* webpackChunkName: "component---src-templates-static-use-case-market-innovators-js" */)
}

